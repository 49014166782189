import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { HomeComponent } from './components/home/home.component';
import { IzveshtaiComponent } from './components/izveshtai/izveshtai.component';
import { NaloziComponent } from './components/nalozi/nalozi.component';
import { DepozitiComponent } from './components/depoziti/depoziti.component';
import { KreditniKartichkiComponent } from './components/kreditni-kartichki/kreditni-kartichki.component';
import { KreditiComponent } from './components/krediti/krediti.component';
import { VnesuvanjeNaloziComponent } from './components/vnesuvanje-nalozi/vnesuvanje-nalozi.component';
import { PotpishuvanjeNaloziComponent } from './components/potpishuvanje-nalozi/potpishuvanje-nalozi.component';
import { IsprakjanjeNaloziComponent } from './components/isprakjanje-nalozi/isprakjanje-nalozi.component';
import { StatusNaloziComponent } from './components/status-nalozi/status-nalozi.component';
import { PromenaLozinkaComponent } from './components/promena-lozinka/promena-lozinka.component';
import { AuthGuardService } from './services/authguard.service';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'main', 
    component: MainComponent,
    children:[
      { path: 'pochetna', component: HomeComponent },
      { path: 'izveshtai', component: IzveshtaiComponent },
      { path: 'nalozi', component: NaloziComponent },
      { path: 'depoziti', component: DepozitiComponent },
      { path: 'kreditniKartichki', component: KreditniKartichkiComponent },
      { path: 'krediti', component: KreditiComponent },
      { path: 'vnesuvanjeNalozi', component: VnesuvanjeNaloziComponent },
      { path: 'potpishuvanjeNalozi', component: PotpishuvanjeNaloziComponent },
      { path: 'isprakjanjeNalozi', component: IsprakjanjeNaloziComponent },
      { path: 'statusNalozi', component: StatusNaloziComponent },
      { path: 'promenaNaLozinka', component: PromenaLozinkaComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
