import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { SidebarWhiteComponent } from './components/sidebar-white/sidebar-white.component';
import { SidebarBlackComponent } from './components/sidebar-black/sidebar-black.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { TranslateService } from './services/translate.service';
import { NgxWebstorageModule} from 'ngx-webstorage';
import { HomeComponent } from './components/home/home.component';
import { IzveshtaiComponent } from './components/izveshtai/izveshtai.component';
import { NaloziComponent } from './components/nalozi/nalozi.component';
import { DepozitiComponent } from './components/depoziti/depoziti.component';
import { KreditniKartichkiComponent } from './components/kreditni-kartichki/kreditni-kartichki.component';
import { KreditiComponent } from './components/krediti/krediti.component';
import { Pp30Component } from './components/pp30/pp30.component';
import { Pp50Component } from './components/pp50/pp50.component';
import { Pp53Component } from './components/pp53/pp53.component';
import { VnesuvanjeNaloziComponent } from './components/vnesuvanje-nalozi/vnesuvanje-nalozi.component';
import { PotpishuvanjeNaloziComponent } from './components/potpishuvanje-nalozi/potpishuvanje-nalozi.component';
import { IsprakjanjeNaloziComponent } from './components/isprakjanje-nalozi/isprakjanje-nalozi.component';
import { StatusNaloziComponent } from './components/status-nalozi/status-nalozi.component';
import { MyDatePickerModule } from 'mydatepicker';
import { PromenaLozinkaComponent } from './components/promena-lozinka/promena-lozinka.component';
import { ChartsModule } from 'ng2-charts';
import { JsonService } from './services/json.service';
import { AuthGuardService } from './services/authguard.service';
import { PagerComponent } from './components/pager/pager.component';
import { PagerService } from './services/pager.service';
import { MomentModule } from 'ngx-moment';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    SidebarWhiteComponent,
    SidebarBlackComponent,
    HeaderComponent,
    TranslatePipe,
    SearchPipe,
    NumberPipe,
    HomeComponent,
    IzveshtaiComponent,
    NaloziComponent,
    DepozitiComponent,
    KreditniKartichkiComponent,
    KreditiComponent,
    Pp30Component,
    Pp50Component,
    Pp53Component,
    VnesuvanjeNaloziComponent,
    PotpishuvanjeNaloziComponent,
    IsprakjanjeNaloziComponent,
    StatusNaloziComponent,
    PromenaLozinkaComponent,
    PagerComponent 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    NgxWebstorageModule.forRoot(),
    ChartsModule,
    MomentModule, 
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [TranslateService,
              JsonService,
              AuthGuardService,
              PagerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
