import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Chart } from 'chart.js'
import { JsonService } from '../../services/json.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-vnesuvanje-nalozi',
  templateUrl: './vnesuvanje-nalozi.component.html',
  styleUrls: ['./vnesuvanje-nalozi.component.css']
})
export class VnesuvanjeNaloziComponent implements OnInit {

  LineChart: any = [];

  pp50ListBlank: any;
  pp50ListFromJson: any;
  showPP50: boolean = false;
  showPP50Data: boolean = false;
  
  pp30ListBlank: any;
  pp30ListFromJson: any;
  showPP30Data: boolean = false;
  showPP30: boolean = true;

  pp53ListBlank: any;
  pp53ListFromJson: any;
  showPP53Data: boolean = false;
  showPP53: boolean = true;

  constructor(private naloziJson: JsonService, private fb: FormBuilder) { }

  ngOnInit() {
    $("[data-demo-action='update']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){                
          
          e.preventDefault();
          
          // app._loading.show(card,{spinner: true});                
          
          setTimeout(function(){
              // app._loading.hide(card);
          },2000);                
          
          return false;
      });            
  });
    $("[data-demo-action='expand']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){
          
          e.preventDefault();                
          
          // app._loading.show(card,{spinner: true});
          
          $(this).toggleClass("active");
          card.toggleClass("card--expanded");
          
          // app._crt();
          
          setTimeout(function(){
              // app._loading.hide(card);
          },1000);
          
          return false;
      });            
  });

  // end expand card button
  
  // invert card button                
  $("[data-demo-action='invert']").each(function(){
      
      var card = $(this).parents(".card");

      if(card.hasClass("invert")){
          $(this).addClass("active");
      }
      
      $(this).click(function(e){
          e.preventDefault();
          
          $(this).toggleClass("active");
          card.toggleClass("invert");
          
          return false;
      });            
  });
  $("button.transform1").click(function(){
    $("span.rotate1").toggleClass("down"); 
});
$("button.transform2").click(function(){
  $("span.rotate2").toggleClass("down"); 
});

  }

  getDataFromJsonPP50() {
    this.naloziJson.getData().subscribe(data => {
      this.pp50ListFromJson = data;
      this.pp50ListBlank = this.pp50ListFromJson.pp50;
      this.showPP50 = false;
      this.showPP50Data = true;
    })
  }

  getDataFromJsonPP30() {
    this.naloziJson.getData().subscribe(data => {
      this.pp30ListFromJson = data;
      this.pp30ListBlank = this.pp30ListFromJson.pp30;
      this.showPP30 = false;
      this.showPP30Data = true;
    })
  }

  getDataFromJsonPP53() {
    this.naloziJson.getData().subscribe(data => {
      this.pp53ListFromJson = data;
      this.pp53ListBlank = this.pp53ListFromJson.pp53;
      this.showPP53 = false;
      this.showPP53Data = true;
    })
  }

  showComponentpp50(){
    this.showPP50 = true;
    this.showPP50Data = false;
  }

  showComponentpp30(){
    this.showPP30 = true;
    this.showPP30Data = false;
  }

  showComponentpp53(){
    this.showPP53 = true;
    this.showPP53Data = false;
  }
  
  
 

}
