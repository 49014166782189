export const LANG_MK_NAME = 'mk';

export const LANG_MK_TRANS = {
    'logo': 'СТОПАНСКА БАНКА А.Д. БИТОЛА',
    'username': 'Корисничко име',
    'password': 'Лозинка',
    'login': 'Најава',
    'platenPromet': 'Платен промет',
    'depoziti': 'Депозити',
    'kreditiranje': 'Кредитирање',
    'izlez': 'Излез'
    
}