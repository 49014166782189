import { Component, OnInit } from '@angular/core';
import { Chart} from 'chart.js';
import { JsonService } from '../../services/json.service';
import { PagerService } from '../../services/pager.service';
declare var $: any;
import * as moment from 'moment';
import { Moment } from 'moment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  selected = {start: moment(), end: moment() };
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Денес': [moment(), moment()],
    'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Претходните 7 дена': [moment().subtract(6, 'days'), moment()],
    'Претходните 30 дена': [moment().subtract(29, 'days'), moment()],
    'Тековен месец': [moment().startOf('month'), moment().endOf('month')],
    'Претходен месец': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  LineChart: any =[];


  pochetnaList: any;
  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  constructor(private pochetnaService: JsonService, private pagerService: PagerService) {}

  

  ngOnInit() {
    this.chart();
    this.getDataPochetna();
  }

  getDataPochetna(){
    this.pochetnaService.getDataPochetna().subscribe(data => {
      this.pochetnaList = data;
      this.setPage(1);
    });
  }

  setPage(page: number) {
 
    let pageSize = 3;
    // get pager object from service
    this.pager = this.pagerService.getPager(this.pochetnaList.length, page, pageSize);

    // get current page of items
    this.pagedItems = this.pochetnaList.slice(this.pager.startIndex, this.pager.endIndex + 1);

  }

  chart() {
    this.LineChart = new Chart('lineChart', {
      type: 'line',
      data: {
        labels: ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [{
          label: 'Потрошени средства',
          data: [9, 7, 3, 5, 2, 10, 15, 16, 19, 3, 1, 9],
          fill: false,
          lineTension: 0.2,
          borderColor: "red",
          borderWidth: 1
        }, {
          label: 'Потрошени средства',
          data: [2, 4, 1, 0.5, 5, 10, 13, 12, 11, 50, 11, 12],
          fill: false,
          lineTension: 0.2,
          borderColor: "green",
          borderWidth: 1

        }]
      },
      options: {
        responsive: true,
        title: {
          text: "Состојба за сметка 500600019306295",
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });


    $("[data-demo-action='update']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){                
          
          e.preventDefault();
          
          // app._loading.show(card,{spinner: true});                
          
          setTimeout(function(){
              // app._loading.hide(card);
          },2000);                
          
          return false;
      });            
  });
    $("[data-demo-action='expand']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){
          
          e.preventDefault();                
          
          // app._loading.show(card,{spinner: true});
          
          $(this).toggleClass("active");
          card.toggleClass("card--expanded");
          
          // app._crt();
          
          setTimeout(function(){
              // app._loading.hide(card);
          },1000);
          
          return false;
      });            
  });

  // end expand card button
  
  // invert card button                
  $("[data-demo-action='invert']").each(function(){
      
      var card = $(this).parents(".card");

      if(card.hasClass("invert")){
          $(this).addClass("active");
      }
      
      $(this).click(function(e){
          e.preventDefault();
          
          $(this).toggleClass("active");
          card.toggleClass("invert");
          
          return false;
      });            
  });
  $("button.transform1").click(function(){
    $("span.rotate1").toggleClass("down"); 
});
$("button.transform2").click(function(){
  $("span.rotate2").toggleClass("down"); 
});
$(".transform3").click(function(){
  $("span.rotate3").toggleClass("down"); 
});
$(".transform4").click(function(){
  $("span.rotate4").toggleClass("down"); 
});
$(".transform5").click(function(){
  $("span.rotate5").toggleClass("down"); 
});


  }

  
  

}
