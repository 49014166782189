import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Input } from '@angular/core';
import { JsonService } from '../../services/json.service';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-pp53',
  templateUrl: './pp53.component.html',
  styleUrls: ['./pp53.component.css']
})
export class Pp53Component implements OnInit {

  pp53Form: FormGroup;
  @Input() pp53List: any;

  constructor(private naloziJson: JsonService,
    private fb: FormBuilder) {

      this.pp53Form = fb.group({
        "nazivNalogoduvach1": [""],
        "nazivNalogoduvach2": [""],
        "bankaNalogoduvach": [""],
        "danocenBroj": [""],
        "datumValuta": [""],
        "vkupenBrNalozi": [""],
        "iznos": [""],
        "prioritet": [""],
        "folio": [""]
      })

    }

  ngOnInit() {  
  }

  ngOnChanges(...args: any[]){
    console.log("pp50",args[0].pp53List.currentValue)
    this.pp53List = args[0].pp53List.currentValue;
    this.getDataFromParentComponent();
  }

  getDataFromParentComponent() {
      this.pp53Form.patchValue({
        nazivNalogoduvach1: this.pp53List[0].nazivNalogoduvach1,
        nazivNalogoduvach2: this.pp53List[0].nazivNalogoduvach2,
        bankaNalogoduvach: this.pp53List[0].bankaNalogoduvach,
        danocenBroj: this.pp53List[0].danochenBroj,
        datumValuta: this.pp53List[0].datumValuta,
        iznos: this.pp53List[0].iznos,
        prioritet: this.pp53List[0].prioritet,
        vkupenBrNalozi: this.pp53List[0].vkupenBrojNalozi,
        folio: this.pp53List[0].folioBroj
      }) 
  }

  selected = {start: moment(), end: moment() };
  alwaysShowCalendars: boolean;
  selected2 = {start: moment(), end: moment() };

}
