import { Component, OnInit } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';
import { Moment } from 'moment';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-depoziti',
  templateUrl: './depoziti.component.html',
  styleUrls: ['./depoziti.component.css']
})
export class DepozitiComponent implements OnInit {
  selected = {start: moment(), end: moment() };
alwaysShowCalendars: boolean;
ranges: any = {
  'Денес': [moment(), moment()],
  'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Претходните 7 дена': [moment().subtract(6, 'days'), moment()],
  'Претходните 30 дена': [moment().subtract(29, 'days'), moment()],
  'Тековен месец': [moment().startOf('month'), moment().endOf('month')],
  'Претходен месец': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
}
// invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

// isInvalidDate = (m: moment.Moment) =>  {
//   return this.invalidDates.some(d => d.isSame(m, 'day') )
// }

  myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd.mm.yyyy',
    showClearDateBtn: false,
    editableDateField: false
  };

  myDatePickerOptions1: IMyDpOptions = {
    // other options...
    dateFormat: 'dd.mm.yyyy',
    showClearDateBtn: false,
    editableDateField: false
  };

  myDatePickerOptions2: IMyDpOptions = {
    // other options...
    dateFormat: 'dd.mm.yyyy',
    showClearDateBtn: false,
    editableDateField: false
  };

  myDatePickerOptions3: IMyDpOptions = {
    // other options...
    dateFormat: 'dd.mm.yyyy',
    showClearDateBtn: false,
    editableDateField: false
  };

  datePicker: any;
  datePicker1: any;
  datePicker2: any;
  datePicker3: any;
  
  constructor() {  this.alwaysShowCalendars = true;}

  ngOnInit() {
    $("[data-demo-action='update']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){                
          
          e.preventDefault();
          
          // app._loading.show(card,{spinner: true});                
          
          setTimeout(function(){
              // app._loading.hide(card);
          },2000);                
          
          return false;
      });            
  });
    $("[data-demo-action='expand']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){
          
          e.preventDefault();                
          
          // app._loading.show(card,{spinner: true});
          
          $(this).toggleClass("active");
          card.toggleClass("card--expanded");
          
          // app._crt();
          
          setTimeout(function(){
              // app._loading.hide(card);
          },1000);
          
          return false;
      });            
  });

  // end expand card button
  
  // invert card button                
  $("[data-demo-action='invert']").each(function(){
      
      var card = $(this).parents(".card");

      if(card.hasClass("invert")){
          $(this).addClass("active");
      }
      
      $(this).click(function(e){
          e.preventDefault();
          
          $(this).toggleClass("active");
          card.toggleClass("invert");
          
          return false;
      });            
  });
  $("button.transform1").click(function(){
    $("span.rotate1").toggleClass("down"); 
});
$("button.transform2").click(function(){
  $("span.rotate2").toggleClass("down"); 
});
$("button.transform3").click(function(){
  $("span.rotate3").toggleClass("down"); 
});
  }

  setDate(): void {
    let date = new Date();
    this.datePicker = {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() - 1,
        day: date.getDate()}
    };
    this.datePicker1 = {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()}
    }; 
    this.datePicker2 = {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()}
    };
    this.datePicker3 = {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()}
    };  
  }

}
