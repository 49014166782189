import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Input } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-sidebar-black',
  templateUrl: './sidebar-black.component.html',
  styleUrls: ['./sidebar-black.component.css']
})
export class SidebarBlackComponent implements OnInit {

  @Input() setClassAside: boolean;
  formNames = [
    {
      ImeForma: 'Почетна',
      ImeForma2: 'pochetna',
      Icon: 'icon li-home'
    },
    {
      ImeForma: 'ТРС извештаи',
      ImeForma2: 'izveshtai',
      Icon: 'icon li-layers'
    },
    {
      ImeForma: 'Налози',
      Icon: 'icon li-menu-square',
      Subitem:[
        {
          ImeForma: 'Внесување на налози',
          ImeForma2: 'vnesuvanjeNalozi',
          Icon: 'icon li-document'
        },
        {
          ImeForma: 'Потпишување на налози',
          ImeForma2: 'potpishuvanjeNalozi',
          Icon: 'icon li-pencil5'
        },
        {
          ImeForma: 'Испраќање на налози',
          ImeForma2: 'isprakjanjeNalozi',
          Icon: 'icon li-bag'
        },
        {
          ImeForma: 'Статус на налози',
          ImeForma2: 'statusNalozi',
          Icon: 'icon li-laptop-phone'
        },    
      ]
    },
    {
      ImeForma: 'Депозити',
      ImeForma2: 'depoziti',
      Icon: 'icon li-wallet'
    },
    {
      ImeForma: 'Кредитни картички',
      ImeForma2: 'kreditniKartichki',
      Icon: 'icon li-credit-card'
    }, 
    {
      ImeForma: 'Кредити',
      ImeForma2: 'krediti',
      Icon: 'icon li-receipt'
    },
    {
      ImeForma: 'Излез',
      ImeForma2: 'izlez',
      Icon: 'icon li-exit-left'
    }
   
  ];


  constructor() { 
  }

  openableSideMenu(){
    $("#liOpen").each(function () {
      $(this).toggleClass("open");
    });
  }

  ngOnInit() {
  }

  ngOnChanges(...args: any[]){
    this.showHide(); 
  }

  showHide(){
    if(this.setClassAside == true){
      $("#page-aside").each(function () {
        $(this).addClass("page-aside--hidden");
      });
    }else{
      $("#page-aside").each(function () {
        $(this).removeClass("page-aside--hidden");
      });
    }
  }


  

}
