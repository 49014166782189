import { Component, OnInit } from '@angular/core';
import { TranslateService } from './services/translate.service';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Chart} from 'chart.js'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'eBanking';
  LineChart :any  = []; 
  @SessionStorage('currentLang')
  lang: any;
  constructor(private translateService: TranslateService,
              private sessionStorage: SessionStorageService) { }

  ngOnInit(){
    this.setLanguage();
    // Line chart:
  

  }

  setLanguage() {
    if (this.sessionStorage.retrieve('currentLang') == null) {
      this.sessionStorage.store('currentLang', 'mk');
    }

    this.lang = this.sessionStorage.retrieve('currentLang');
    this.translateService.use(this.lang);
  }

}
