import { Component, OnInit } from '@angular/core';
import { JsonService } from '../../services/json.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-pp50',
  templateUrl: './pp50.component.html',
  styleUrls: ['./pp50.component.css']
})
export class Pp50Component implements OnInit {


  pp50Form: FormGroup;
  @Input() pp50List: any;

  constructor(private naloziJson: JsonService,
    private fb: FormBuilder) {

    this.pp50Form = fb.group({
      "nazivNalogoduvach1": [""],
      "nazivNalogoduvach2": [""],
      "bankaNalogoduvach": [""],
      "danocenBroj": [""],
      "povikuvanjeNaBrojZadolzuvanje": [""],
      "celDoznaka1": [""],
      "celDoznaka2": [""],
      "nazivPrimach1": [""],
      "nazivPrimach2": [""],
      "bankaPrimach": [""],
      "iznos": [""],
      "uplatnaSmetka1": [""],
      "uplatnaSmetka2": [""],
      "uplatnaSmetka3": [""],
      "smetkaNaKorisnik1": [""],
      "smetkaNaKorisnik2": [""],
      "smetkaNaKorisnik3": [""],
      "prihodnaShifra": [""],
      "programa": [""],
      "prioritet": [""],
      "smetkaNaPrimach": [""]
    })
  }

  ngOnInit() {
  }

  ngOnChanges(...args: any[]){
    console.log("pp50",args[0].pp50List.currentValue)
    this.pp50List = args[0].pp50List.currentValue;
    this.getDataFromParentComponent();
  }

  getDataFromParentComponent() {
      this.pp50Form.patchValue({
        nazivNalogoduvach1: this.pp50List[0].nazivNalogoduvach1,
        nazivNalogoduvach2: this.pp50List[0].nazivNalogoduvach2,
        bankaNalogoduvach: this.pp50List[0].bankaNalogoduvach,
        danocenBroj: this.pp50List[0].danocenBroj,
        povikuvanjeNaBrojZadolzuvanje: this.pp50List[0].povikuvanjeNaBrojZadolzuvanje,
        celDoznaka1: this.pp50List[0].celDoznaka1,
        celDoznaka2: this.pp50List[0].celDoznaka2,
        nazivPrimach1: this.pp50List[0].nazivPrimach1,
        nazivPrimach2: this.pp50List[0].nazivPrimach2,
        bankaPrimach: this.pp50List[0].bankaPrimach,
        iznos: this.pp50List[0].iznos,
        uplatnaSmetka1: this.pp50List[0].uplatnaSmetka1,
        uplatnaSmetka2: this.pp50List[0].uplatnaSmetka2,
        uplatnaSmetka3: this.pp50List[0].uplatnaSmetka3,
        smetkaNaKorisnik1: this.pp50List[0].smetkaNaKorisnik1,
        smetkaNaKorisnik2: this.pp50List[0].smetkaNaKorisnik2,
        smetkaNaKorisnik3: this.pp50List[0].smetkaNaKorisnik3,
        prihodnaShifra: this.pp50List[0].prihodnaShifra,
        programa: this.pp50List[0].programa,
        prioritet: this.pp50List[0].prioritet,
        smetkaNaPrimach: this.pp50List[0].smetkaNaPrimach
      }) 
  }

  selected = {start: moment(), end: moment() };
  alwaysShowCalendars: boolean;
  selected2 = {start: moment(), end: moment() };
  

}
