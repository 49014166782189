import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nalozi',
  templateUrl: './nalozi.component.html',
  styleUrls: ['./nalozi.component.css']
})
export class NaloziComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
