import {Injectable, Inject} from '@angular/core';
import { LANG_EN_NAME, LANG_EN_TRANS } from '../../assets/translate/lang-en';
import { LANG_MK_NAME, LANG_MK_TRANS} from '../../assets/translate/lang-mk';

const dictionary = {
    [LANG_EN_NAME]: LANG_EN_TRANS,
    [LANG_MK_NAME]: LANG_MK_TRANS
};

@Injectable()
export class TranslateService {
    private _currentLang: string;

    public get currentLang() {
        return this._currentLang;
    }

    // inject our translations
    constructor() {

    }

    public use(lang: string): void {
        // set current language
        this._currentLang = lang;
    }

    public translate(key: string): string {
        let translation = key;

        if (dictionary[this.currentLang] && dictionary[this.currentLang][key]) {
            return dictionary[this.currentLang][key];
        }

        return translation;
    }

    public instant(key: string) {
        // call translation
        return this.translate(key);
    }
}
