import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Moment } from 'moment';
import * as moment from 'moment';
@Component({
  selector: 'app-status-nalozi',
  templateUrl: './status-nalozi.component.html',
  styleUrls: ['./status-nalozi.component.css']
})
export class StatusNaloziComponent implements OnInit {

  selected = {start: moment(), end: moment() };
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Денес': [moment(), moment()],
    'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Претходните 7 дена': [moment().subtract(6, 'days'), moment()],
    'Претходните 30 дена': [moment().subtract(29, 'days'), moment()],
    'Тековен месец': [moment().startOf('month'), moment().endOf('month')],
    'Претходен месец': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  constructor() { }

  ngOnInit() {
    $("[data-demo-action='update']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){                
          
          e.preventDefault();
          
          // app._loading.show(card,{spinner: true});                
          
          setTimeout(function(){
              // app._loading.hide(card);
          },2000);                
          
          return false;
      });            
  });
    $("[data-demo-action='expand']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){
          
          e.preventDefault();                
          
          // app._loading.show(card,{spinner: true});
          
          $(this).toggleClass("active");
          card.toggleClass("card--expanded");
          
          // app._crt();
          
          setTimeout(function(){
              // app._loading.hide(card);
          },1000);
          
          return false;
      });            
  });

  // end expand card button
  
  // invert card button                
  $("[data-demo-action='invert']").each(function(){
      
      var card = $(this).parents(".card");

      if(card.hasClass("invert")){
          $(this).addClass("active");
      }
      
      $(this).click(function(e){
          e.preventDefault();
          
          $(this).toggleClass("active");
          card.toggleClass("invert");
          
          return false;
      });            
  });
  $("button.transform1").click(function(){
    $("span.rotate1").toggleClass("down"); 
});
  }

}
