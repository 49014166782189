import { Component, OnInit } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';
import { Moment } from 'moment';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-kreditni-kartichki',
  templateUrl: './kreditni-kartichki.component.html',
  styleUrls: ['./kreditni-kartichki.component.css']
})
export class KreditniKartichkiComponent implements OnInit {

  selected = {start: moment(), end: moment() };
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Денес': [moment(), moment()],
    'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Претходните 7 дена': [moment().subtract(6, 'days'), moment()],
    'Претходните 30 дена': [moment().subtract(29, 'days'), moment()],
    'Тековен месец': [moment().startOf('month'), moment().endOf('month')],
    'Претходен месец': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  selected2 = {start: moment(), end: moment() };
  alwaysShowCalendars2: boolean;
  ranges2: any = {
    'Денес': [moment(), moment()],
    'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Претходните 7 дена': [moment().subtract(6, 'days'), moment()],
    'Претходните 30 дена': [moment().subtract(29, 'days'), moment()],
    'Тековен месец': [moment().startOf('month'), moment().endOf('month')],
    'Претходен месец': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  selected3 = {start: moment(), end: moment() };
  alwaysShowCalendars3: boolean;
  ranges3: any = {
    'Денес': [moment(), moment()],
    'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Претходните 7 дена': [moment().subtract(6, 'days'), moment()],
    'Претходните 30 дена': [moment().subtract(29, 'days'), moment()],
    'Тековен месец': [moment().startOf('month'), moment().endOf('month')],
    'Претходен месец': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  
  constructor() { }
 
  ngOnInit() {

  //   document.addEventListener("DOMContentLoaded", function () {
  //     $("#dp-example-viewYears").datetimepicker({viewMode: "years", format: "YYYY"});
  //     // $("#dp-example-viewMonths").datetimepicker({viewMode: "months", format: "MM"});                                                                                                
  //     // $("#dp-example-viewDay").datetimepicker({viewMode: "days", format: "DD"});
  
  // });
 
  
    $("[data-demo-action='update']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){                
          
          e.preventDefault();
          
          // app._loading.show(card,{spinner: true});                
          
          setTimeout(function(){
              // app._loading.hide(card);
          },2000);                
          
          return false;
      });            
  });
    $("[data-demo-action='expand']").each(function(){
            
      var card = $(this).parents(".card");
      
      $(this).click(function(e){
          
          e.preventDefault();                
          
          // app._loading.show(card,{spinner: true});
          
          $(this).toggleClass("active");
          card.toggleClass("card--expanded");
          
          // app._crt();
          
          setTimeout(function(){
              // app._loading.hide(card);
          },1000);
          
          return false;
      });            
  });
  // end expand card button
  
  // invert card button                
  $("[data-demo-action='invert']").each(function(){
      
      var card = $(this).parents(".card");
  
      if(card.hasClass("invert")){
          $(this).addClass("active");
      }
      
      $(this).click(function(e){
          e.preventDefault();
          
          $(this).toggleClass("active");
          card.toggleClass("invert");
          
          return false;
      });            
  });
  $("button.transform1").click(function(){
    $("span.rotate1").toggleClass("down"); 
});
$("button.transform2").click(function(){
  $("span.rotate2").toggleClass("down"); 
});
$(".transform3").click(function(){
  $("span.rotate3").toggleClass("down"); 
});
$(".transform4").click(function(){
  $("span.rotate4").toggleClass("down"); 
});
  }
  
}
