import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

import * as moment from 'moment';
import { Moment } from 'moment';
import { JsonService } from '../../services/json.service';
import { Input } from '@angular/core';

import { LocaleConfig } from 'ngx-daterangepicker-material';




@Component({
  selector: 'app-pp30',
  templateUrl: './pp30.component.html',
  styleUrls: ['./pp30.component.css']
})
export class Pp30Component implements OnInit {
  
  pp30: FormGroup;
  @Input() pp30List: any;


  constructor(private fb: FormBuilder, private naloziJson: JsonService) { 
    this.pp30 = this.fb.group({
      'datumValuta' :[""],
      'smetkaNalogodavac' : [""],
      'smetkaPrimac' : [""],
      'nazivSedisteNalogodavac1' : [""],
      'nazivSedisteNalogodavac2' : [""],
      'nazivSedistePrimac1' : [""],
      'nazivSedistePrimac2' :[""],
      'bankaNalogodavac' : [""],
      'bankaPrimac' : [""], 
      'celDoznaka1' :[""],
      'celDoznaka2' : [""],
      'iznos' : [""],
      'povikuvanjeBrojZadolzuvanje' : [""],
      'povikuvanjeBrojOdobruvanje' : [""],
      'Sifra' : [""],
      'Prioritet' : [""],
      'Nacin' : [""],
      'datumPodnesuvanje' : [""]
    })
  }
  
  ngOnInit() {
  }

  ngOnChanges(...args: any[]){
    console.log("pp30",args[0].pp30List.currentValue)
    this.pp30List = args[0].pp30List.currentValue;
    this.getDataFromParentComponent();
  }

  getDataFromParentComponent() {
      this.pp30.patchValue({
       datumValuta: this.pp30List[0].datumValuta,
       smetkaPrimac: this.pp30List[0].smetkaNaPrimach,
       nazivSedisteNalogodavac1: this.pp30List[0].nazivNalogoduvach1,
       nazivSedisteNalogodavac2: this.pp30List[0].nazivNalogoduvach2,
       nazivSedistePrimac1: this.pp30List[0].nazivPrimach1,
       nazivSedistePrimac2: this.pp30List[0].nazivPrimach2,
       bankaNalogodavac: this.pp30List[0].bankaNalogoduvach,
       bankaPrimac: this.pp30List[0].bankaPrimach,
       celDoznaka1: this.pp30List[0].celDoznaka1,
       celDoznaka2: this.pp30List[0].celDoznaka2,
       iznos: this.pp30List[0].iznos,
       povikuvanjeBrojOdobruvanje: this.pp30List[0].povikuvanjeNaBrojOdobruvanje,
       povikuvanjeBrojZadolzuvanje: this.pp30List[0].povikuvanjeNaBrojZadolzuvanje,
       Sifra: this.pp30List[0].shifra,
       Prioritet: this.pp30List[0].prioritet,
       Nacin: this.pp30List[0].nacin
      }) 
  }
  selected = {start: moment(), end: moment() };
  alwaysShowCalendars: boolean;
  selected2 = {start: moment(), end: moment() };
  

}







