import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  urlList: any;
  title: any;
  flag: boolean;
  @Output() hideSidebarBlack = new EventEmitter();

  constructor(private router: Router) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.urlList = event;    
        if(this.urlList.url == "/main/pochetna"){
          this.title = "Почетна";
        }else if(this.urlList.url == "/main/izveshtai"){
          this.title = "ТРС извештаи";
        }else if(this.urlList.url == "/main/kreditniKartichki"){
          this.title = "Кредитни картички";
        }else if(this.urlList.url == "/main/krediti"){
          this.title = "Кредити";
        }else if(this.urlList.url == "/main/depoziti"){
          this.title = "Депозити";
        }else if(this.urlList.url == "/main/vnesuvanjeNalozi"){
          this.title = "Внесување на налози";
        }else if(this.urlList.url == "/main/potpishuvanjeNalozi"){
          this.title = "Потпишување на налози";
        }else if(this.urlList.url == "/main/isprakjanjeNalozi"){
          this.title = "Испраќање на налози";
        }else if(this.urlList.url == "/main/statusNalozi"){
          this.title = "Статус на налози";
        }
      });
  }

  ngOnInit() {
  }

  clickBtn(){
    this.flag = !this.flag;
    this.hideSidebarBlack.emit(this.flag);
  }



}
