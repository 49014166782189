import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JsonService {

  constructor(private http: HttpClient) { }


  getData() {
    return this.http.get('../assets/nalozi.json');
  }

  getDataPochetna(){
    return this.http.get('../assets/pochetna.json');
  }

  getDataTRS(){
    return this.http.get('../assets/trs.json');
  }

  getDataDepoziti(){
    return this.http.get('../assets/depoziti.json');
  }

  getDataKartichki(){
    return this.http.get('../assets/kartichki.json');
  }

  getDataKrediti(){
    return this.http.get('../assets/amort.json');
  }

}
